import React from 'react'
import Layout from '../layouts/Layout'
import I18n from '../utils/I18n'
import Companies from '../components/Companies'

const HomeTemplate = data => {
  const { pageContext: { lang } } = data
  const i18n = new I18n({ lang })

  return (
    <Layout>
      <div>
        <tm-page>
          <div>
            <th-element-loader element="tm-page" style={{ height: '100vh' }} />
          </div>

          <div>
            <tm-hero-a
              background="light"
              img-src="https://developer.salesforce.com/resources2/trailblazer-connect/director/hero.png?v=2"
              img-alt={i18n.text('header_image_alt')}
              title={i18n.text('header_title')}
              subtitle={i18n.text('header_subtitle')}
              cta-label="Learn More"
              cta-href={`#company-directories`}
            />
          </div>
          <tm-section-a>
            <tm-end-cap-a
              background="dark"
              img-src="https://developer.salesforce.com/resources2/trailblazer-connect/director/end-cap-img.png"
              img-alt="Become a Workforce Partner image"
              title="Become a Workforce Partner"
              subtitle="Be part of a global movement to develop the next generation of Trailblazers."
              cta-href={`#teach-salesforce-program`}
              cta-label="Learn More"
            />
          </tm-section-a>
          
          <tm-section-a title={i18n.text('directory_title')} id='company-directories'>
            <Companies lang={lang} />
          </tm-section-a>

          <tm-section-a
            id='teach-salesforce-program'
            description="According to IDC, the Salesforce economy is set to create 9.3 million new jobs by 2026. We help training, non-profit, and higher education organizations teach Salesforce skills in their communities and classrooms, so more Trailblazers can pursue technology, sales, service, and marketing jobs in the Salesforce ecosystem." title="Teach Salesforce in Your Program"
          ></tm-section-a>

          <tm-section-a title="Workforce Partner Program Benefits">
            <tm-card-grid-c img-aspect="60%">
              <tm-grid-item
                description="Get exclusive access to content, trailmixes, certification vouchers and more."
                img-src="https://developer.salesforce.com/resources2/wfp/Partner-Tile1.png" imgp-alt=""
                pretitle=""
                title="Support and Resources">
              </tm-grid-item>
              <tm-grid-item
                description="Collaborate with other Workforce Development Organizations and employers, get support, and share best practices."
                img-alt=""
                img-src="https://developer.salesforce.com/resources2/wfp/Partner-Tile2.png" pretitle=""
                title="Vibrant Community">
              </tm-grid-item>
              <tm-grid-item
                description="Showcase your Salesforce learning with our Trailblazer Workforce Partner mark and listing on the partner directory."
                img-alt=""
                img-src="https://developer.salesforce.com/resources2/wfp/Partner-Tile3.png" pretitle=""
                title="Marketing Support">
              </tm-grid-item>
            </tm-card-grid-c>
          </tm-section-a>

          <tm-section-a
            cta-href="/en/workforce-partners/apply"
            cta-title="Apply Here"
            title="How To Join">
            <tm-card-grid-a img-aspect="60%" img-type="left">
              <tm-grid-item
                description="Share more information about your program and how you plan to incorporate Salesforce and Trailhead training."
                img-alt="Number One"
                img-src="https://developer.salesforce.com/resources2/wfp/Partner-Tile4.png" title="Submit Your Application">
              </tm-grid-item>
              <tm-grid-item
                description="If accepted, you get access to a dedicated team, partner directory listing, program community, ecosystem updates, and invitations to events." img-alt="Number two"
                img-src="https://developer.salesforce.com/resources2/wfp/Partner-Tile5.png" title="Access Program Benefits">
              </tm-grid-item>
              <tm-grid-item
                description="Share your metrics and success stories with us regularly." img-alt="Number three"
                img-src="https://developer.salesforce.com/resources2/wfp/Partner-Tile6.png" title="Share Your Impact">
              </tm-grid-item>
            </tm-card-grid-a>
          </tm-section-a>

          <tm-section-a>
            <tm-promo-c
              background="light"
              description="Are you an employer looking to connect with Salesforce-skilled talent? Salesforce Talent Alliance connects new Salesforce professionals with employers ready to hire. Pledge to build a diverse workforce and join today. "
              img-src="https://developer.salesforce.com/resources2/wfp/TalentAlliance.png" primary-cta-href="http://sforce.co/TalentAlliance"
              primary-cta-title="Join Now">
            </tm-promo-c>
          </tm-section-a>

          <tm-section-a>
            <tm-end-cap-a
              background="dark"
              cta-href="mailto:trailblazerworkforcepartner@salesforce.com?subject=Trailblazer%20Workforce%20Partner%20[Website]"
              cta-label="Contact Us"
              img-alt="backpack image"
              img-src="https://developer.salesforce.com/resources2/wfp/Partner-Endcap.png" subtitle="Reach out to the Workforce Partner team for questions and support." title="Get In Touch">
            </tm-end-cap-a>
          </tm-section-a>
        </tm-page>
      </div>

    </Layout>
  )
}

export default HomeTemplate;
